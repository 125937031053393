<template>
  <div class="container dangerInfo-warp  flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/yh.png')" alt="" />
      <div class="head-bottom-warp">
        <div class="head-item align-left blue">
          <div class="bg-blur"></div>
          <img
            :src="require('@/assets/image/yhicon.png')"
            class="img-icon"
          /><span>隐患点详情</span>
        </div>
      </div>
    </div>
    <div class="panel-warp flexone">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">隐患点名称</div>
          <div class="panel-item-right bra3 panel-item-block plr">{{forms.name}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">隐患类型</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ $dt.changeCodeName('hida_type', forms.type )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">隐患级别</div>
          <div class="panel-item-right bra3 panel-item-block plr">
                {{ $dt.changeCodeName('hida_level', forms.level )}}
          </div>
        </li>
          <li>
          <div class="panel-item-left bra3 panel-item-block">一级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaOne}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">二级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaTwo}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">三级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaThree}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">详细地址</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.addr}}</div>
        </li>
        <li >
          <div class="panel-item-left bra3 panel-item-block">地图</div>
          <div class="panel-item-right bra3 panel-item-block fill" style="position:relative;">
            <div class="baidu-mao-model"  @click.stop="showaction = true"></div>
            <baidu-map  v-if="forms.id" style="height:150px;width:100%" :min-zoom="10" :max-zoom="10" :center="center" :zoom="zoom" @ready="handler">
               <!-- bm-marker 就是标注点 定位在point的经纬度上 -->
              <bm-marker
                :position="center"
                :dragging="false"
                animation="BMAP_ANIMATION_BOUNCE"
              >
                <!-- bm-label 显示标注点名称  -->
              <!-- <bm-label
              :content="addressName"
              :offset="{ width: -35, height: 30 }"
              /> -->
              </bm-marker>
            </baidu-map>
          </div>
        </li>

         <li>
          <div class="panel-item-left bra3 panel-item-block">照片</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            <img
               v-for="(item,index) in forms.photos"
              :key="index"
              :src="item"
              class="panel-img-square"
              alt=""
              @click="handlePreImg(forms.photos,index)"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            备注信息
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{forms.remark}}
          </div>
        </li>
      </ul>
      <van-image-preview v-model="show" closeable :images="preImages" :start-position="startIndex"> 
      </van-image-preview>
      <van-action-sheet v-model="showaction" :actions="actions" @select="onSelect" cancel-text="取消"
      close-on-click-action />
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin/detailMixin";
export default {
  mixins: [mixin],
  data() {
    return {
      interfaceUrl: "/api/hida/get",
      center: {lng: 0, lat: 0},
    // 地图放大等级
      zoom: 3,
      showaction:false,
      actions:[{ name: '高德地图',code:1 }, { name: '腾讯地图',code:2 }, { name: '百度地图',code:3}]
    };
  },
  mounted() {},
  methods: {
     // 实例对象
    handler ({BMap, map}) {
      this.$nextTick(() => {
        let pos = this.forms.pos.split(',')
        console.log(pos,'111')
            // 经度
        this.center.lng = pos[0] || 0
        // 纬度
        this.center.lat = pos[1] || 0
        // 地图放大等级
        this.zoom = 10
      })
    
    },
    onSelect({code}) {
      if (code == 1) {
        window.location.href = `http://uri.amap.com/marker?position=${this.center.lng},${this.center.lat}&name=${this.forms.addr}&coordinate=gaode&callnative=1`
      } else if(code == 2) {
        window.location.href = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${this.center.lat},${this.center.lng};addr:${this.forms.addr}`

      } else if (code == 3) {
         window.location.href = `http://api.map.baidu.com/marker?location=${this.center.lng},${this.center.lat}&title=${this.forms.addr}&content=&output=html`
      }
    }
  },
};
</script>

<style lang="less">
.houseInfo-warp {
  letter-spacing: 0px;
  position: relative;
}
.baidu-mao-model {
   position: absolute;
   left:0;
   top:0;
   right:0;
   bottom:0;
   z-index:1;
}
</style>
